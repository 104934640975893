/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    div: "div",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Widex Beyond is one of the ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "latest hearing aids"), " from the Danish manufacturer, ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Widex"), ". In this case, the company has set out to create a hearing aid that is specifically adapted to the needs of ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/iphone/",
    className: "c-md-a"
  }, "iPhone users"), ", meaning that the Widex Beyond incorporates a host of advanced features. Optimized for streaming movies and music from Apple’s tablets and smartphones, the Beyond has also been engineered to work with telecoils and smart TVs or audio systems via DEX technology. And Widex has also updated their iPhone app to provide a greater degree of personalization and control. It all adds up to a cutting-edge hearing aid which suits digital entertainment fans and professional users alike. But let’s dig a little deeper to find out everything the Widex Beyond has to offer."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "one-of-the-first-made-for-iphone-hearing-aid-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#one-of-the-first-made-for-iphone-hearing-aid-devices",
    "aria-label": "one of the first made for iphone hearing aid devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "One of the first made-for-iPhone hearing aid devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As we noted at the start, the main selling point of the Widex Beyond is the way it connects with Apple’s range of digital communication devices, which includes iPods, iPads, and Apple Watches, as well as iPhones (from the iPhone 4 onwards). Thanks to the Beyond App, users can take control of their directional hearing functions, volume settings, and the balance between two hearing aids, arriving at customized solutions for different settings and individuals. The app can be downloaded for free from the iTunes Store, and it automatically updates itself when the hearing aids are powered up. It needs to be paired up with the hearing aid, but Widex makes the procedure fairly simple, and all users should be able to do so without any problems. When the Beyond App is installed, online tutorials also make it simpler to use functions like the Sound Mixer. And when users have become accustomed to what it has to offer, the app could provide an unprecedented level of control over your hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "advanced-wireless-tech",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced-wireless-tech",
    "aria-label": "advanced wireless tech permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced wireless tech"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While the hearing aid is highly customizable, it’s also designed to deliver high-quality audio. There’s no point in having an iPhone set up to stream your favorite tracks or TV shows, if the streaming audio is flawed. The Widex Beyond gets around this problem by integrating 2.4 GhZ wireless streaming into its design. The on-board processor also includes features like 100 percent channel separation, ensures very low levels of latency, and includes four A/D converters. These features help to beef up the sound the hearing aid produces, and smooths out the signal, helping hearing aid users enjoy the music they love, for longer period of time. Meanwhile, the Widex Beyond also has features that help make it easier to hear in conversations. The Sound Equalizer tool can be used to focus on speakers with incredible precision, ensuring that the words they say come over in crystal clarity. The U-Platform also delivers a high degree of directional performance and sound cancellation, ensuring that complex environments like restaurants or bars don’t sound like a cacophony of sounds. This enables users to communicate with others, hear their conversation partners, and negotiate social situations in much more comfort."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "energy-efficient",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#energy-efficient",
    "aria-label": "energy efficient permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Energy efficient"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another key feature of the Widex Beyond is its efficient design. Compared with older hearing aids, the Beyond is extremely energy efficient, despite featuring more advanced processing components to handle its made-for-iPhone streaming functions. An intelligent design means that it uses around 2mA of current during everyday use, much less than standard models. However, buyers should be aware that not all Widex Beyond devices have the same power features. The standard Widex Beyond model relies on battery power, while the newer Widex Beyond Z is fully rechargeable. Then again, the company allows existing users to “retrofit” their devices to add the rechargeable technology (for a fee), so you can start off with battery-based options, then trade up if you feel the need for a more flexible hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "sophisticated-settings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sophisticated-settings",
    "aria-label": "sophisticated settings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sophisticated settings"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another attractive feature of the Widex Beyond devices is their range of special features. Many of these can be a handy supplement to the lifestyles of hearing aid users. For example, the Beyond features something called a Smartwind Manager. This part of the hearing aid’s processing unit is focused on canceling out the interference caused by wind, which can be a huge irritation for wearers. When the Smartwind Manager is applied, even high winds should be muted, allowing wearers to hear conversations when the weather is poor. This kind of feature means that the Widex Beyond can perform well in outdoor and indoor environments, something which doesn’t apply to all hearing aids on the market. Additionally, Widex Beyond hearing aids can be equipped with the manufacturer’s ZEN system for countering tinnitus. By generating “fractal tones,” ZEN helps its wearer to soothe the experience of tinnitus."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "choose-the-right-model-of-widex-beyond",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choose-the-right-model-of-widex-beyond",
    "aria-label": "choose the right model of widex beyond permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choose the right model of Widex Beyond"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As with most hearing aid models, the Widex Beyond comes in a variety of models, and the prices differ considerably. All of them are Receiver-in-Canal hearing aids (RIC), which is something that buyers will want to bear in mind. The choices vary from the Widex Beyond 110, through the 220, 330, and the 440. The Beyond 440 is the most advanced model available, with all the features we’ve talked about, and the power to cater to severe levels of hearing loss. But any Widex Beyond branded hearing aid will feature the same iPhone compatibility, allowing users to stream straight from their smartphone to their hearing aid, without the need for a device in between. The major differences revolve around the number of custom settings available. For example, the Widex Beyond 440 offers five settings, while four are available with the 330. That’s a big deal for some users, but not a deal breaker for everyone. So, have a look at the features and choose something that meets your individual needs."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Widex Beyond now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
